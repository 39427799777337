/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { getPlanStatusActiveInactiveNotBuyYet } from './request';

type Props = {
  className: string
}
const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const TablesWidget5: React.FC<Props> = ({className}) => {
    const [userData,setUserData]=useState<any[]>([])
    const [status,setStatus]=useState<string>("active")
    const findPaymentStatus=async ()=>{
      const {data}=await getPlanStatusActiveInactiveNotBuyYet("active")
      if(data?.success){
        setUserData(data?.data)
      }
      else{
        setUserData([])
      }
    }
    useEffect(()=>{
      findPaymentStatus()
    },[])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Client Plan Status</span>
          {/* <span className='text-muted mt-1 fw-bold fs-7'>More than 400 new products</span> */}
        </h3>
        <div className='card-toolbar col-4'>
          {/* <ul className='nav'>
            <li className='nav-item me-2'>
              <button
                 className="btn btn-primary"
              >
                Active
              </button>
            </li>
            <li className='nav-item ms-2'>
              <button
                 className="btn btn-danger"
              >
                In Active
              </button>
            </li>
            <li className='nav-item ms-2'>
              <button
                 className="btn btn-warning"
              >
                Not Buy Yet
              </button>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_3'
              >
                Day
              </a>
            </li>
          </ul> */}
           <select className="react-select form-control form-select" value={status} onChange={async (e:any)=>{
                setStatus(e?.target?.value)
                const {data}=await getPlanStatusActiveInactiveNotBuyYet(e?.target?.value)
                if(data?.success){
                  setUserData(data?.data)
                }
                else{
                  setUserData([])
                }
           }}>
             <option className="text-primary" selected>Select Status</option>
             <option value="active">Active</option>
             <option value="expired">Expired</option>
           </select>
        </div>
      </div>
      <hr/>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-150px text-primary fw-bolder fs-5'>Full Name</th>
                    <th className='p-0 min-w-150px text-primary fw-bolder fs-5'>Email</th>
                    <th className='p-0 min-w-150px text-primary fw-bolder fs-5'>Plan Name</th>
                    <th className='p-0 min-w-150px text-primary fw-bolder fs-5'>Payment Method</th>
                    <th className='p-0 min-w-150px text-primary fw-bolder fs-5'>Plan Sarting Date</th>
                    <th className='p-0 min-w-150px text-primary fw-bolder fs-5'>Plan Expiry Date</th>
                    {/* <th className='p-0 min-w-50px'></th> */}
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                 {userData?.length>0 && userData?.map((item:any,index:number)=>{
                      return  <tr key={index}>
                      <td className='text-center text-muted fw-bold'>{item?.full_name}</td>
                        <td className='text-center text-muted fw-bold'>
                            <BootstrapTooltip title={`${item?.user_email}`}>
                              <Button className="text-dark fw-bolder text-muted" style={{textTransform:"lowercase"}}>{item?.user_email?.length>10?`${item?.user_email?.substring(0,10)}...`:item?.user_email}</Button>
                            </BootstrapTooltip>
                        </td>
                        <td className='text-center text-muted fw-bold'>{item?.plan_name}</td>
                        <td className='text-center text-muted fw-bold'>{item?.payment_method}</td>
  
                        <td className='text-center text-muted fw-bold'><span className='badge badge-light-success'>
                        {String(new Date(item?.plan_starting_date).getDate()+"-"+new Date(item?.plan_starting_date).toLocaleString('default', { month: 'long' })+"-"+new Date(item?.plan_starting_date).getFullYear())}
</span></td>
    
                        <td className='text-center  text-muted fw-bold'> <span className='badge badge-light-danger'>
                        {String(new Date(item?.plan_expiry_date).getDate()+"-"+new Date(item?.plan_expiry_date).toLocaleString('default', { month: 'long' })+"-"+new Date(item?.plan_expiry_date).getFullYear())}
</span></td>
    
                      </tr>
                 }) 
                 }
                 
                </tbody>
                {/* end::Table body */}
              </table>
                 {
                    userData?.length<=0 && <h4 className="my-5 text-center text-danger">No Records Available.</h4>
                  }
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/plurk.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        Brad Simmons
                      </a>
                      <span className='text-muted fw-bold d-block'>Movie Creator</span>
                    </td>
                    <td className='text-end text-muted fw-bold'>React, HTML</td>
                    <td className='text-end'>
                      <span className='badge badge-light-success'>Approved</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        Popular Authors
                      </a>
                      <span className='text-muted fw-bold d-block'>Most Successful</span>
                    </td>
                    <td className='text-end text-muted fw-bold'>Python, MySQL</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>In Progress</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        Active Customers
                      </a>
                      <span className='text-muted fw-bold d-block'>Movie Creator</span>
                    </td>
                    <td className='text-end text-muted fw-bold'>AngularJS, C#</td>
                    <td className='text-end'>
                      <span className='badge badge-light-danger'>Rejected</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/kickstarter.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        Bestseller Theme
                      </a>
                      <span className='text-muted fw-bold d-block'>Best Customers</span>
                    </td>
                    <td className='text-end text-muted fw-bold'>ReactJS, Ruby</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>In Progress</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/bebo.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        Active Customers
                      </a>
                      <span className='text-muted fw-bold d-block'>Movie Creator</span>
                    </td>
                    <td className='text-end text-muted fw-bold'>AngularJS, C#</td>
                    <td className='text-end'>
                      <span className='badge badge-light-danger'>Rejected</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/vimeo.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        New Users
                      </a>
                      <span className='text-muted fw-bold d-block'>Awesome Users</span>
                    </td>
                    <td className='text-end text-muted fw-bold'>Laravel,Metronic</td>
                    <td className='text-end'>
                      <span className='badge badge-light-primary'>Success</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl('/media/svg/brand-logos/telegram.svg')}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>
                        Popular Authors
                      </a>
                      <span className='text-muted fw-bold d-block'>Most Successful</span>
                    </td>
                    <td className='text-end text-muted fw-bold'>Python, MySQL</td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning'>In Progress</span>
                    </td>
                    <td className='text-end'>
                      <a
                        href='#'
                        className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr064.svg'
                          className='svg-icon-2'
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TablesWidget5}
