import axios from "axios"

const API_URL_ADMIN_SETTING=window.location.host=="localhost:3011"?"http://localhost:5006/api/conferenceQuiz/quiz/admin":"https://quiz.quizophy.com/api/conferenceQuiz/quiz/admin"

export const getPlanStatusActiveInactiveNotBuyYet=(status)=>{
    return axios.get(`${API_URL_ADMIN_SETTING}/get-plan-status/${status}`)
}
export const getTotalTransactionCountForEveryMonthAccordingToYear=(start,end,email)=>{
   return axios.get(`${API_URL_ADMIN_SETTING}/getTotalTransactionForEveryMonth/${start}/${end}?email=${email}`)
}
export const getAllRegistereduserList=()=>{
   return axios.get(`${API_URL_ADMIN_SETTING}/getAllRegisteredUser`)
}

export const getTotalAmountAccordingToMonthEveryYear=(start,end,currency,email)=>{
    return axios.get(`${API_URL_ADMIN_SETTING}/getTotalPayableAmountAccordingToYearCurrencyEmail/${start}/${end}?currency=${currency}&email=${email}`)
}